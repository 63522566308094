html {
    overflow-y: scroll;
}

body {
    font-size: 12px;
    font-family: Arial, "Segoe UI", Verdana, Helvetica, Sans-Serif;
    color: #5F5F5F;
    background-color: #EDEDED;
}

    body[contenteditable=true] {
        background-color: white;
    }

@media print {
    .no-print {
        display: none;
    }
}

.hiscoxRed {
    color: #DA291C;
}

.hiscoxGreen {
    color: #00C350;
}

.body {
    margin: 0 auto;
    width: 1070px;
}

a {
    text-decoration: none;
    outline: none;
}

img {
    vertical-align: text-bottom;
    border: none;
}

a:visited {
    color: #5F5F5F;
}

a:link {
    color: #5F5F5F;
}

.content-main {
    margin-left: 15px;
    width: 780px;
    padding: 20px 40px;
}

.fixed {
    position: fixed;
}

.rightAlign {
    float: right;
}

.content-inner {
    width: 780px;
}

#map {
    position: relative;
    margin: 0 auto;
    border: 10px solid #EDEDED;
    width: 700px;
    height: 400px;
}

#world {
    position: absolute;
    left: 65px;
    top: 460px;
    cursor: pointer;
}

.heading {
    float: left;
    width: 150px;
    font-weight: bold;
}

.error {
    color: Red;
}

.success {
    color: Green;
    font-weight: bold;
}

.mandatory {
    cursor: default;
    color: Red;
}

.previous-value {
    cursor: default;
    color: Blue;
}

.bin, .head-bin, .head-edit {
    cursor: default;
}

hr {
    border: none;
    height: 1px;
    background-color: #EAEAEA;
}

    hr.bold {
        height: 2px;
    }

    hr.divider {
        width: 90%;
    }

    hr.wide {
        position: relative;
        left: -40px;
        width: 860px;
    }

.header {
    position: relative;
    background: white;
}

tr.item-row {
    width: 776px;
    position: relative;
    background: white;
    display: block;
}

    tr.item-row td {
        padding-top: 5px;
        padding-bottom: 5px;
        border-bottom: 1pt solid #EAEAEA;
    }

tr.checkbox-item td {
    padding-top: 0px;
    padding-bottom: 0px;
    border-bottom: none;
}

tr.radio-item td {
    padding-top: 0px;
    padding-bottom: 0px;
    border-bottom: none;
}

.draggable {
    cursor: move;
    z-index: 99900;
}

.hidden {
    display: none !important;
}

.full-width {
    width: 738px;
}

.head-bin, .head-edit {
    float: right;
    width: 53px;
}

.indent-0 {
    padding-left: 0px;
}

.indent-1 {
    padding-left: 50px;
}

.indent-2 {
    padding-left: 100px;
}

.indent-3 {
    padding-left: 150px;
}

.indent-4 {
    padding-left: 200px;
}

.dark-box {
    background-color: #EDEDED;
    margin-right: 20px;
    padding: 5px;
}

.table.multiple {
    background: #EDEDED;
}

.message-view-header {
    width: 100px;
}

.message-view-value {
    width: 750px;
}

.communications {
    border-collapse: collapse;
    border-spacing: 0;
    margin: 3px 5px;
}

    .communications th {
        font-size: 0.9em;
        border: 1px solid #CDCDCD;
        background: #EDEDED;
        padding: 3px;
    }

    .communications td {
        font-size: 0.9em;
        border: 1px solid #CDCDCD;
        padding: 5px;
        background: white;
    }

    .communications .credit-card {
        font-weight: bold;
        color: #DA291C;
        margin-top: 15px;
    }

    .communications .subject {
        width: 200px;
    }

    .communications .message {
        width: 300px;
    }


    .communications .user {
        width: 140px;
    }

    .communications .attachments {
        width: 250px;
    }

    .communications .type {
        width: 200px;
    }

    .communications .date {
        width: 78px;
    }

    .communications .status {
        width: 300px;
    }

    .communications .status-narrow {
        width: 222px;
    }

    .communications .version {
        width: 78px;
    }

    .communications .version-date {
        width: 200px;
    }

    .communications .status {
        width: 300px;
    }

    .communications .premium {
        width: 85px;
    }

    .communications .updated-by {
        width: 75px;
    }

#spacer {
    height: 0px;
}

#navigator {
    position: fixed;
    z-index: 99997;
}

.content-main, .navigator-section {
    border: 1px solid #CDCDCD;
    background: white;
}

.header-box, .document-container {
    border: 1px solid #CDCDCD;
    width: 350px;
    background: #EDEDED;
    padding: 5px;
    margin: 5px;
}

.header-box-tall {
    height: 25px;
    padding-top: 10px;
}

.header-box-padded {
    padding: 10px 0px 20px 20px;
}

.document-area {
    margin-top: 5px;
    padding: 5px;
    background: white;
}

.small-input {
    width: 180px !important;
}

select.small-input {
    width: 186px !important;
}

.tiny-input {
    width: 120px !important;
}

select.tiny-input {
    width: 126px !important;
}

select.tall {
    height: 21px;
}

.tiny-input[type="checkbox"] {
    width: 20px !important;
}

input[type="radio"].spaced {
    margin-left: 20px !important;
}

.dialog {
    background: white;
}

.ui-dialog {
    z-index: 99998 !important;
}

.ui-dialog-titlebar {
    height: 16px;
}

.droppable {
    width: 750px;
    padding: 10px;
    border: 1px dashed silver;
}

.padded-bottom {
    padding-bottom: 5px;
}

iframe {
    width: 800px;
    height: 600px;
    overflow: hidden;
}

    iframe[src*="Templates"] {
        height: 500px;
    }

    iframe.tall {
        height: 640px !important;
    }

.slick-grid-tall {
    width: 800px;
    height: 500px;
}

.slick-grid-medium {
    width: 800px;
    height: 360px;
}

.slick-grid-short {
    width: 800px;
    height: 300px;
}

.slickgrid-red {
    background-color: #D52B1E !important;
    color: #FFFFFF !important;
}

.slickgrid-orange {
    background-color: #FDC82F !important;
}

.slick-grid-filter {
    margin: 0px 0px 0px -4px;
    font-size: 0.7em;
    height: 12px;
    padding-right: 18px;
}

    .slick-grid-filter::-ms-clear {
        display: none;
    }

.clear-filter {
    text-indent: -1000em;
    width: 9px;
    height: 8px;
    display: inline-block;
    background-image: url("images/clear.png");
    background-repeat: no-repeat;
    position: relative;
    left: -16px;
    top: 5px;
}

.slick-column-name {
    font-weight: bold;
}

.slick-cell {
    padding-left: 5px !important;
    font-size: 0.9em;
}

.slick-header-column {
    border-top: 1px solid silver !important;
}

.slick-cell.l0 {
    border-left: 1px solid silver !important;
}

.slick-cell, .slick-headerrow-column {
    border-right: 1px solid silver !important;
}

    .slick-cell.wrapped {
        white-space: pre-wrap !important;
        white-space: -moz-pre-wrap !important;
        word-wrap: break-word !important;
    }

.slick-cell, .ui-accordion-header {
    cursor: pointer !important;
}

ul.plain {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

ul.nodot {
    list-style-type: none;
    padding-left: 15px;
}

.select2-drop {
    z-index: 99999 !important;
}

.select2-drop-mask {
    z-index: 99999 !important;
}

select, .select2-container {
    width: 290px;
}

.dropzone-dropper {
    border: 5px;
    border-style: double;
    background: white;
    padding: 10px;
    width: 400px;
}

.dropzone-dropper-small {
    border: 5px;
    border-style: double;
    background: white;
    padding: 10px;
    width: 300px;
}

.dropzone-dropper-smaller {
    border-radius: 3px;
    border: #000 1px solid;
    box-shadow: 0 0 0 3px #DA291C, 0 0 0 4px #000;
    background: white;
    padding: 4px;
    width: 110px;
}

/*#dropzone-helper {
    border: 1px dashed Black;
    background: #EDEDED;
    margin: 10px;
    padding: 25px;
    width: 700px;
}*/

.dropzone-helper {
    border: 1px dashed Black;
    background: #EDEDED;
    margin: 10px;
    padding: 25px;
    width: 675px;
}



.day-picker {
    width: 50px;
}

.month-picker {
    width: 135px;
}

.year-picker {
    width: 70px;
}

.numeric, .decimal {
    text-align: right;
}

div.item:not(.droppable) {
    width: 288px;
}

div.item.wide {
    width: 738px !important;
}

input[type="text"]:not(.k-input):not(.k-textbox), input[type="password"], input[type="email"] {
    width: 289px;
}

input[type="text"].search {
    width: 200px;
}

input.attachment-add {
    width: 670px;
}

input.wide, .select2-container.wide {
    width: 700px;
}

input.fluid, .select2-container.fluid {
    width: 100%;
}


input[type="submit"], input[type="button"] {
    width: 140px;
}

#delete-selected {
    width: 160px;
}

#close-editor {
    width: 40px;
}

h1 {
    margin-top: 0px;
    font-size: 1.8em;
}

h2 {
    margin-top: 0px;
    margin-bottom: 5px;
}

h3 {
    margin-top: 0px;
    margin-bottom: 5px;
    font-size: 1.3em;
}

h5 {
    margin-top: 10px;
    margin-bottom: 2px;
    font-size: 8pt;
}

th {
    text-align: left;
}

td {
    vertical-align: top;
}

tr.bold > td {
    font-weight: bold;
}

td.spacer {
    width: 100px;
}

td.text {
    width: 350px;
}

td.response {
    width: 300px;
}

    td.response.wide {
        width: 735px;
    }

td.trailing-text {
    width: 80px;
}

td.navigator {
    width: 182px;
}

td.subtle {
    color: Silver;
    font-style: italic;
}

.breadcrumb {
    padding-left: 16px;
}

.title {
    font-weight: bold;
}

label.small {
    font-size: 0.9em;
    font-weight: bold;
    margin-right: 7px;
}

.menu {
    padding-left: 20px;
}

    .menu a {
        font-size: 0.9em;
        font-weight: bold;
    }

    .menu p img {
        padding-right: 8px;
    }

    .menu .draggable {
        font-size: 0.9em;
        font-weight: bold;
    }

    .menu table {
        border-spacing: 0px;
    }

    .menu td {
        padding-top: 4px;
        padding-bottom: 12px;
        padding-right: 5px;
    }

#team-selector {
    width: 200px;
}

.key-item {
    float: left;
    width: 14px;
    height: 14px;
    margin-right: 5px;
}

.g-key-item {
    float: left;
    height: 14px;
    margin: 0 5px;
    padding: 2px;
    font-size: 0.8em;
}

.criteria-item {
    float: left;
    width: 15px;
    height: 8px;
    font-size: 0.8em;
    background-color: #CDCDCD;
    color: #CDCDCD;
    box-shadow: inset 0 0 0 1000px #CDCDCD;
    margin-right: 10px;
}

.criteria-item-selected {
    background-color: #D52B1E !important;
    color: #D52B1E !important;
    box-shadow: inset 0 0 0 1000px #D52B1E;
}

.small-header {
    height: 20px;
    font-size: 0.8em;
}

.doughnut-chart-container {
    position: relative;
    height: 255px;
}

.doughnut-chart {
    width: 225px;
}

.doughnut-name {
    position: absolute;
    left: 93px;
    top: 94px;
    text-align: center;
    font-size: 1.1em;
    font-weight: bold;
}

.doughnut-name-2 {
    position: absolute;
    left: 340px;
    top: 103px;
    text-align: center;
    font-size: 1.1em;
    font-weight: bold;
}

.doughnut-name-3 {
    position: absolute;
    left: 77px;
    top: 103px;
    text-align: center;
    font-size: 1.1em;
    font-weight: bold;
}

.doughnut-no-data {
    position: absolute;
    left: 93px;
    top: 94px;
    text-align: center;
    font-size: 3em;
    font-weight: bold;
}

.spacer {
    width: 30px;
}

div.separator {
    padding: 0px 16px;
}

.useful-docs {
    width: 600px;
}

td.useful-doc {
    padding: 12px;
}

.image-border {
    width: 67px;
    border: 1px solid #CDCDCD;
}

.clickable {
    cursor: pointer;
}

#graph-key {
    padding-left: 60px;
    width: 180px;
}

.summary-value {
    font-weight: bold;
    padding-right: 20px;
}

#s2id_statuses {
    width: 610px;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.pad-right {
    padding-right: 25px;
}

.text-right {
    text-align: right;
}

.main-menu {
    box-sizing: border-box;
    width: 180px;
    border-radius: 8px;
    border: 1px solid #CDCDCD;
    background: #fff;
    margin-bottom: 5px;
    padding-bottom: 5px;
}

    .main-menu .draggable {
        display: block;
        padding: 2px 20px;
        white-space: nowrap;
    }

    .main-menu a {
        display: block;
        padding: 6px 20px;
        margin: 1px 0;
        white-space: nowrap;
    }

    .main-menu .logo {
        padding: 8px 15px;
    }

    .flyoutPanel img, .flyoutPanel i, .main-menu img, .main-menu i {
        padding: 0 8px 0 0;
    }

    .main-menu .flyoutmenu {
        border-right: 3px solid;
    }

        .main-menu .flyoutmenu:hover {
            background: #ccc;
        }

.flyoutmenu {
    padding-right: 5px;
    position: relative;
    cursor: pointer;
}

.main-menu .flyoutmenu:after {
    position: absolute;
    content: '+';
    top: 8px;
    right: 3px;
}

.flyoutmenu:hover .flyoutPanel {
    display: block;
}

.flyoutPanel {
    z-index: 1;
    display: none;
    position: absolute;
    top: 13px;
    background: #fff;
    border-radius: 5px;
    border: 1px solid #CDCDCD;
    padding: 15px 20px;
    white-space: nowrap;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
    top: 13px;
}

.menudown {
    right: 0;
}

.menuright {
    left: 178px;
    margin-top: -50px;
}

.flyoutPanel p {
    text-align: center;
    font-weight: bold;
    margin: 5px 0;
}

.flyoutPanel .col {
    display: inline-block;
    vertical-align: top;
}

.flyoutPanel a:hover {
    background: #ccc;
}

.flyoutPanel a {
    padding: 5px;
    margin: 0;
    display: block;
}


/* Corner radius */
.ui-corner-all,
.ui-corner-top,
.ui-corner-left,
.ui-corner-tl {
    border-top-left-radius: 8px;
}

.ui-corner-all,
.ui-corner-top,
.ui-corner-right,
.ui-corner-tr {
    border-top-right-radius: 8px;
}

.ui-corner-all,
.ui-corner-bottom,
.ui-corner-left,
.ui-corner-bl {
    border-bottom-left-radius: 8px;
}

.ui-corner-all,
.ui-corner-bottom,
.ui-corner-right,
.ui-corner-br {
    border-bottom-right-radius: 8px;
}

header, footer, nav, section {
    display: block;
}


/* Styles for basic forms
-----------------------------------------------------------*/
fieldset {
    border: 1px solid #ddd;
    padding: 0 1.4em 1.4em 1.4em;
    margin: 0 0 1.5em 0;
}

legend {
    font-size: 1.2em;
    font-weight: bold;
}

textarea {
    font-family: Arial, "Segoe UI", Verdana, Helvetica, Sans-Serif;
    width: 284px;
    min-height: 100px;
}

    textarea.wide {
        width: 700px;
        min-height: 80px;
    }

    textarea.tall {
        width: 700px;
        min-height: 600px;
    }

    textarea.autogrow {
        height: 15px;
        min-height: 15px;
        overflow: hidden;
    }

.editor-label, .display-label {
    margin: 1em 0 0 0;
    font-weight: bold;
}

.editor-field {
    margin: 0.5em 0 0 0;
}

.warning {
    border: 1px solid Black;
    color: White;
    background: #D52B1E;
    padding: 5px;
    margin: 3px;
    border-radius: 4px;
}

    .warning a {
        color: Black;
        padding-left: 20px;
    }

.quote-option-message a {
    padding-left: 20px;
}

.info {
    border: 1px solid Black;
    color: Black;
    background: #FDC82F;
    padding: 5px;
    margin: 3px;
    border-radius: 4px;
}

.quote-option-message {
    border: 1px solid Black;
    color: White;
    background: #686868;
    padding: 5px;
    margin: 3px;
    border-radius: 4px;
}

    .quote-option-message a {
        color: White;
    }

.org-chart {
    text-align: center;
    padding-left: 20px;
    overflow: auto;
    width: 780px;
}

.org-chart-key {
    padding-left: 60px;
}

.google-visualization-orgchart-node {
    border-radius: 4px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

    .google-visualization-orgchart-node[title=Broker] {
        background: none;
        background-color: #0573E1 !important;
        border: 2px solid #0573E1 !important;
        color: White;
    }

    .google-visualization-orgchart-node[title=Underwriter] {
        background: none;
        background-color: #DA291C !important;
        border: 2px solid #DA291C !important;
        color: White;
    }

    .google-visualization-orgchart-node[title=Operations] {
        background: none;
        background-color: #00C350 !important;
        border: 2px solid #00C350 !important;
        color: White;
    }

    .google-visualization-orgchart-node[title=Agency] {
        background: none;
        background-color: #BEBEBE !important;
        border: 2px solid #BEBEBE !important;
        color: Black;
    }

    .google-visualization-orgchart-node[title=Producer] {
        background: none;
        background-color: #FEDD00 !important;
        border: 2px solid #FEDD00 !important;
        color: Black;
    }

    .google-visualization-orgchart-node[title=Intermediary] {
        background: none;
        background-color: #00B4E1 !important;
        border: 2px solid #00B4E1 !important;
        color: White;
    }

    .google-visualization-orgchart-node[title=DelegatedUnderwriter], .google-visualization-orgchart-node[title='Delegated Underwriter'] {
        background: none;
        background-color: #FFC000 !important;
        border: 2px solid #FFC000 !important;
        color: White;
    }


.google-visualization-orgchart-lineleft {
    border-left: 1px solid #cdcdcd !important;
}

.google-visualization-orgchart-lineright {
    border-right: 1px solid #cdcdcd !important;
}

.google-visualization-orgchart-linebottom {
    border-bottom: 1px solid #cdcdcd !important;
}

.google-visualization-orgchart-linetop {
    border-top: 1px solid #cdcdcd !important;
}
/* Styles for validation helpers
-----------------------------------------------------------*/
.field-validation-error {
    color: #f00;
}

.field-validation-valid {
    display: none;
}

.input-validation-error {
    border: 1px solid #f00;
    background-color: #fee;
}

.validation-summary-errors {
    font-weight: bold;
    color: #f00;
}

.validation-summary-valid {
    display: none;
}

#overlay {
    background: #000;
    display: none;
    top: 0;
    left: 0;
    z-index: 99925;
    position: fixed;
    bottom: 0;
    right: 0;
}

#big-info {
    background: #eeeeee;
    border: 3px solid #D52B1E;
    padding: 20px;
    margin-left: -90px;
    width: 180px;
    top: 250px;
    left: 50%;
    z-index: 99999;
    position: fixed;
    border-radius: 8px;
}

    #big-info h1 {
        text-align: center;
    }

#submenu-container-inner {
    position: relative;
    background: white;
    z-index: 99950;
}

.yes-dependant {
    background: #f2fff2 !important;
}

.no-dependant {
    background: #fff2f2 !important;
}

.conditional-dependant {
    background: #c9e6ff !important;
}

.comment {
    font-size: 0.8em;
    font-style: italic;
}

.ui-sortable-handle li {
    cursor: n-resize;
}

.ui-dialog {
    position: absolute;
}

    .ui-dialog.ui-widget-content, .ui-datepicker.ui-widget-content {
        background: #fdfdfd;
        border: 2px solid #D52B1E;
        color: #222222;
    }

    .ui-dialog .ui-dialog-content {
        background: none repeat scroll 0 0 transparent;
        border: 0 none;
        overflow: auto;
        position: relative;
        padding: 10px 15px !important;
    }

    .ui-dialog .ui-widget-header {
        margin: 2px;
        background: #D52B1E;
        border: 0;
        color: #fff;
        font-weight: normal;
    }

    .ui-dialog .ui-dialog-titlebar {
        padding: 0.5em .5em;
        position: relative;
        font-size: 1em;
    }

    .ui-dialog .ui-dialog-titlebar-close {
        position: absolute;
        right: .3em;
        top: 50%;
        width: 21px;
        margin: -10px 0 0 0;
        padding: 1px;
        height: 20px;
    }

    .ui-dialog .ui-state-default {
        border: 1px solid #d3d3d3;
    }

.ui-dialog-buttonpane .ui-button {
    margin: 10px;
}

.ui-icon, .ui-widget-content .ui-icon {
    background-image: url("dist/themes/base/images/ui-icons_222222_256x240.png");
}

.ui-icon-circle-triangle-w {
    height: 16px;
    width: 16px;
    background-repeat: no-repeat;
    display: block;
    overflow: hidden;
    text-indent: -99999px;
    background-position: -80px -192px;
}

.ui-icon-circle-triangle-e {
    height: 16px;
    width: 16px;
    background-repeat: no-repeat;
    display: block;
    overflow: hidden;
    text-indent: -99999px;
    background-position: -48px -192px;
}

.tabs .ui-tabs-panel {
    height: 550px;
}

.ui-tabs {
    margin: 0px -40px;
    position: relative;
}

    .ui-tabs .ui-tabs-nav {
        margin: 0;
        padding: .2em .2em 0;
        border-bottom: 1px solid #CDCDCD;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }

        .ui-tabs .ui-tabs-nav li {
            background: #EDEDED;
            list-style: none;
            float: left;
            position: relative;
            top: 0;
            margin: 1px .2em 0 0;
            border: 1px solid #CDCDCD;
            border-bottom: 1px solid #EDEDED;
            padding: 0;
            white-space: nowrap;
        }

            .ui-tabs .ui-tabs-nav li a {
                float: left;
                padding: .2em 1em;
                text-decoration: none;
                font-size: 0.9em;
                font-weight: bold;
            }

            .ui-tabs .ui-tabs-nav li.ui-tabs-active {
                background: #ffffff;
                border-bottom: 1px solid white;
                margin-bottom: -1px;
                padding-bottom: 1px;
            }

                .ui-tabs .ui-tabs-nav li.ui-tabs-active a,
                .ui-tabs .ui-tabs-nav li.ui-state-disabled a,
                .ui-tabs .ui-tabs-nav li.ui-tabs-loading a {
                    cursor: text;
                }

            .ui-tabs .ui-tabs-nav li a, /* first selector in group seems obsolete, but required to overcome bug in Opera applying cursor: text overall if defined elsewhere... */
            .ui-tabs-collapsible .ui-tabs-nav li.ui-tabs-active a {
                cursor: pointer;
            }

    .ui-tabs .ui-tabs-panel {
        display: block;
        border-width: 0;
        padding: 1em 1.4em;
        background: none;
    }

.ui-helper-clearfix:before, .ui-helper-clearfix:after {
    border-collapse: collapse;
    content: "";
    display: table;
}

.ui-helper-clearfix:after {
    clear: both;
}

.ui-helper-clearfix:before, .ui-helper-clearfix:after {
    border-collapse: collapse;
    content: "";
    display: table;
}

.ui-tabs .ui-tabs-nav {
    margin: 0;
    padding: 0.2em 0.2em 0;
}

div.tab {
    padding-left: 40px;
}

#quote-display td {
    height: 28px;
    padding-right: 10px;
}

    #quote-display td.first {
        font-weight: bold;
    }


/* angular styles*/

.textArea2rows {
    width: 810px;
    min-height: 20px;
}


.errorMessage {
    color: #a94442;
    font-weight: 700;
}

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
    display: none !important;
}

.table {
    color: #666;
    background-color: #fff;
    border-spacing: 0;
    border-collapse: collapse;
}

    .table thead th {
        background-color: #DBDBD7;
        font-weight: bold;
        text-align: center;
        padding-left: 0;
        padding-right: 0;
        white-space: normal;
        vertical-align: middle;
        border: 1px solid #999;
        border-collapse: collapse;
    }

    .table .right {
        text-align: right;
    }

    .table tbody tr:hover {
        background-color: #DBDBD7;
    }

    .table td {
        border: 1px solid #999;
        border-collapse: collapse;
        padding: 5px;
    }

.dropzone {
    border: 2px dashed #ccc;
    background-color: #eee;
    padding: 10px;
}

.spinner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    -ms-opacity: 0.5;
    opacity: 0.5;
    margin: auto;
    text-align: center;
}

    .spinner i {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }

/* Z-index fixes for various flavours of modal pop ups - START */

.ngdialog.ngdialog-theme-default {
    padding-bottom: 160px;
    padding-top: 160px;
    z-index: 199998 !important;
}


/* Z-index fixes for various flavours of modal pop ups - End */

/* Notes Viewer Directive Start */

.ppNotesViewer del {
    background: #FFE6E6;
}

.ppNotesViewer ins {
    background: #E6FFE6;
}

.ppNotesViewer .k-grid td {
    vertical-align: top;
}

.ppNotesViewer .k-detail-row .k-alt {
    background-color: #ebebeb
}

.ppNotesViewer .dropMessage {
    font-size: x-small;
    margin-left: 10px;
    float: left;
    margin-bottom: 10px;
}

.ppNotesViewer .noteCounter {
    float: right;
    font-size: x-small;
}

.ppNotesViewer .notesDropZone {
    border: 2px dashed grey;
    background: #ccc;
    padding: 25px;
    margin: 10px 0;
    display: none;
    text-align: center;
    clear: both;
}

.ppNotesViewer .notesFileList {
    list-style: none;
    clear: both;
    margin-top: 15px;
}

.k-textbox.quote-grid-filter-cell {
    width: 100% !important;
    background-color: white;
}

#kendo-quote-grid .k-filtercell > span > .k-button {
    padding: 0;
}

#team-bank-accounts .k-grid .k-tooltip.k-tooltip-validation {
    display: none !important;
}

.spacer10 {
    margin-top: 10px;
}

.spacer20 {
    margin-top: 20px;
}

.shTable td:nth-child(1) {
    background-color: rgba(0, 0, 0, .02)
}

.shTable td:nth-child(3) {
    background-color: rgba(0, 0, 0, .02)
}


/* Slip Posting History End*/


/* slip post dialog start*/

#slip-post-dialog .k-edit-form-container, #frm-finadmin .k-edit-form-container, .frm-add-lookup .k-edit-form-container {
    width: 100%;
}

    #slip-post-dialog .k-edit-form-container .k-edit-field, #frm-finadmin .k-edit-form-container .k-edit-field {
        float: left;
        margin-left: 10px;
    }

    #slip-post-dialog .k-edit-form-container .k-edit-field, #slip-post-dialog .k-edit-form-container input[type="text"], #frm-finadmin .k-edit-form-container input[type="text"] {
        width: 165px !important;
    }

        #slip-post-dialog .k-edit-form-container input[type="text"].k-inline {
            width: 145px !important;
        }

    #slip-post-dialog .k-edit-form-container .k-datepicker input[type="text"] {
        width: 135px !important;
    }

    #slip-post-dialog .k-edit-form-container input[readonly] {
        background-color: #f9fafc;
    }



    #slip-post-dialog .k-edit-form-container .dialog-data-col {
        float: left;
        width: 33%;
    }

#slip-post-dialog .k-widget.k-tooltip-validation {
    position: absolute;
}

#slip-post-dialog .k-edit-label {
    width: 40%;
}

#slip-post-dialog .status, #frm-finadmin .status, .frm-add-lookup .status {
    float: left;
}


#slip-post-dialog .invalid, #frm-finadmin .invalid, .frm-add-lookup .invalid {
    color: red;
}

#claim-indicator {
    border: 1px solid #CDCDCD;
    width: 320px;
    margin: 5px;
    padding: 5px 20px;
}

    #claim-indicator.closed {
        background: #ffc100;
        color: #5F5F5F;
        cursor: pointer;
    }

    #claim-indicator.open {
        background: #DA291C;
        color: white;
        cursor: pointer;
    }

    #claim-indicator.none {
        background: #EDEDED;
        color: #5F5F5F;
    }

    #claim-indicator a {
        color: inherit;
    }

#slip-post-dialog .valid, #frm-finadmin .valid {
    color: green
}

#slip-post-dialog input[name="gbpRate"]::-webkit-outer-spin-button,
#slip-post-dialog input[name="gbpRate"]::-webkit-inner-spin-button,
#slip-post-dialog input[name="iptRate"]::-webkit-outer-spin-button,
#slip-post-dialog input[name="iptRate"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

#slip-post-dialog .k-tooltip-validation {
    position: absolute;
    /*margin-left or right : give the exact position where you would like to show the message.*/
}

#slip-post-dialog span.k-tooltip {
    display: block;
}

/* slip post dialog end*/


#frm-finadmin .k-edit-form-container {
    margin-top: 50px;
}

    #frm-finadmin .k-edit-form-container .k-edit-field {
        margin-bottom: 20px;
    }

#frm-finadmin .k-edit-label {
    width: 100px;
}

#add-claim input[readonly] {
    background-color: #f9fafc;
    border-style: solid;
    border-width: 1px;
    /*padding: 2px;*/
    /*border-color: grey;*/
    color: gray;
}

.is-link-like:hover {
    cursor: pointer
}

#add-customer-dialog > div {
    float: left;
    width: 300px;
}

#add-customer-dialog .k-dropdown-wrap {
    width: 266px;
    margin-top: .5em;
}

#add-customer-dialog .k-textbox {
    width: 95%;
    font-size: 12px;
    padding: .25em 0;
    background-color: #fff;
    color: #676767;
    border-radius: 2px;
    padding-left: 4px;
}

#add-customer-dialog .k-select {
    border-color: #aaa;
    padding-left: 4px;
    border-radius: 4px;
    outline: 0;
    height: 25px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

#add-customer-dialog .editor-field .k-textbox {
    margin-bottom: 2px;
}

#add-customer-dialog input[type='checkbox'] {
    margin-bottom: 0.65em
}


#idTagSelTop_editorobj, #idTagSelBottom_editorobj {
    display: none;
}

.bold {
    font-weight: bold;
}


.document-area #listView {
    height: 350px;
    overflow-y: scroll;
    padding: 10px 5px;
}

.file-uploader-content {
    max-height: 100px;
    overflow-y: auto;
    padding: 3px;
}

.elispse-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

input.file-uploader {
    display: none;
}

#doc-creator .k-edit-buttons {
    margin-top: 3px;
}


#messages .content p, #message-edit-content p {
    margin: 0;
}

.editControls {
    font-size: 12px;
}

    .editControls .fa:hover,
    .k-button.k-button-icontext.k-grid-remove .fa:hover,
    .grid-download .fa:hover,
    .grid-item .fa:hover {
        color: #d52b1e !important;
    }

    .editControls .fa,
    .grid-item .fa,
    .grid-download .fa,
    .k-command-cell .fa {
        color: #737163 !important;
    }

.ngdialog-content {
    border: 2px solid grey;
}

    .ngdialog-content .ui-button {
        border-style: solid;
        cursor: pointer;
    }

h2.hiscoxRed, h3.ui-accordion-header {
    outline: none
}

.k-grid tbody .k-grid-remove,
.k-grid tbody .k-grid-download,
.k-button.grid-download
{
    min-width: 0 !important;
    margin: 0 !important;
}

#claim-report-grid.k-grid tbody tr td,
#team-list-grid.k-grid tbody tr td,
#customer-list-grid.k-grid tbody tr td,
#user-list-grid.k-grid tbody tr td,
#producer-list-grid.k-grid tbody tr td {
    white-space: nowrap;
    -ms-text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
}

#change-password-form .pass-wrapper {
    width: 293px;
    margin-top: 20px;
    display: none !important;
}

.show-password-eye {
    top: -20px;
    right: 5px;
    cursor: pointer;
    position: relative;
    float: right;
}

/*Hide the default eye icon in IE*/
input::-ms-reveal{
    display:none;
}

#summary-detail-instalments .k-grid-content,
#grid-instalments .k-grid-content {
    height: 73px;
}

.k-progressbar-horizontal {
    height: 7px;
}


.k-tooltip-content {
    color: #fff;
    font-size: 12px;
}

.k-tooltip.k-popup {
    white-space: nowrap
}

.select2-search input{
    width:100% !important;
}

option[value="?"]{
    display:none;
} 

.pad-top-16 {
    padding-top: 16px;
}