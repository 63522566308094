
/* Communication Viewer Styles - begin */

#messageAdd h4, #notificationAdd h4 {
    margin: 1px;
}

.list-unstyled {
    list-style: none;
    float: left;
}


.list-unstyled-inline > li {
    list-style: none;
    display: inline;
    list-style-type: none;
    padding-right: 10px;
    float: left;
}

.messageHeader {
    padding: 10px;
    padding-bottom: 5px;
    padding-right: 5px;
    font-size: 0.90em;
}

.messageContent {
    font-size: 0.85em;
    width: 790px;
    padding: 10px;
    clear: both;
}

.messageInfo {
    float: left;
    width: 735px;
    cursor: pointer;
}

.messageDate {
    float: left;
    width: 100px;
}

.messageFrom {
    float: left;
    width: 200px;
    margin-left: 10px;
}

.messageTo {
    float: left;
    width: 220px;
    margin-left: 10px;
}

.messageArrow {
    float: left;
    width: 20px;
    margin-left: 10px;
}

.messageUser {
    float: left;
    width: 150px;
    margin-left: 10px;
}

.messageSubject {
    float: left;
    width: 600px;
    margin-left: 110px;
    clear: both;
}

.messageControlsWrapper {
    float: right;
    width: 56px;
    margin-left: 10px;
}

    .messageControlsWrapper.editControls {
        float: right;
        clear: both;
        cursor: pointer;
    }

.editControls a {
    margin-right: 5px;
    cursor: pointer;
}

.messageAttachments {
    clear: both;
    font-size: .85em;
    margin-left: 70px;
    width: 550px;
    padding-top: 1px;
}

    .messageAttachments li {
        display: inline;
        list-style-type: none;
        padding-right: 10px;
    }

    .messageAttachments ul {
        margin-bottom: 0px;
    }

    .messageAttachments img {
        vertical-align: middle;
    }

.k-invalid {
    border-color: #d44950 !important;
    background-color: #fffafa !important;
}


/* Communication Viewer Styles - end */
