/* ------------------------------------------- */
/* Mixins
/* ------------------------------------------- */
/* ------------------------------------------- */
/* Toggle Fade  
/* ------------------------------------------- */
/* line 64, ../sass/ng-animation.scss */
.toggle {
  -webkit-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -moz-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -ms-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -o-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
  /* easeOutQuad */
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -moz-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -ms-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -o-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  /* easeOutQuad */
}
/* line 66, ../sass/ng-animation.scss */
.toggle.ng-enter {
  opacity: 0;
  transition-duration: 250ms;
  -webkit-transition-duration: 250ms;
}
/* line 71, ../sass/ng-animation.scss */
.toggle.ng-enter-active {
  opacity: 1;
}
/* line 75, ../sass/ng-animation.scss */
.toggle.ng-leave {
  opacity: 1;
  transition-duration: 250ms;
  -webkit-transition-duration: 250ms;
}
/* line 80, ../sass/ng-animation.scss */
.toggle.ng-leave-active {
  opacity: 0;
}
/* line 85, ../sass/ng-animation.scss */
.toggle.ng-hide-add {
  transition-duration: 250ms;
  -webkit-transition-duration: 250ms;
  opacity: 1;
}
/* line 89, ../sass/ng-animation.scss */
.toggle.ng-hide-add.ng-hide-add-active {
  opacity: 0;
}
/* line 94, ../sass/ng-animation.scss */
.toggle.ng-hide-remove {
  transition-duration: 250ms;
  -webkit-transition-duration: 250ms;
  display: block !important;
  opacity: 0;
}
/* line 99, ../sass/ng-animation.scss */
.toggle.ng-hide-remove.ng-hide-remove-active {
  opacity: 1;
}

/* ------------------------------------------- */
/* Slide Top 
/* ------------------------------------------- */
/* line 109, ../sass/ng-animation.scss */
.slide-top {
  -webkit-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -moz-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -ms-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -o-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
  /* easeOutQuad */
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -moz-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -ms-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -o-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  /* easeOutQuad */
}
/* line 111, ../sass/ng-animation.scss */
.slide-top.ng-enter {
  transform: translateY(60px);
  -ms-transform: translateY(60px);
  -webkit-transform: translateY(60px);
  transition-duration: 250ms;
  -webkit-transition-duration: 250ms;
  opacity: 0;
}
/* line 117, ../sass/ng-animation.scss */
.slide-top.ng-enter-active {
  transform: translateY(0);
  -ms-transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
}
/* line 122, ../sass/ng-animation.scss */
.slide-top.ng-leave {
  transform: translateY(0);
  -ms-transform: translateY(0);
  -webkit-transform: translateY(0);
  transition-duration: 250ms;
  -webkit-transition-duration: 250ms;
  opacity: 1;
}
/* line 128, ../sass/ng-animation.scss */
.slide-top.ng-leave-active {
  transform: translateY(60px);
  -ms-transform: translateY(60px);
  -webkit-transform: translateY(60px);
  opacity: 0;
}
/* line 134, ../sass/ng-animation.scss */
.slide-top.ng-hide-add {
  transform: translateY(0);
  -ms-transform: translateY(0);
  -webkit-transform: translateY(0);
  transition-duration: 250ms;
  -webkit-transition-duration: 250ms;
  opacity: 1;
}
/* line 139, ../sass/ng-animation.scss */
.slide-top.ng-hide-add.ng-hide-add-active {
  transform: translateY(60px);
  -ms-transform: translateY(60px);
  -webkit-transform: translateY(60px);
  opacity: 0;
}
/* line 145, ../sass/ng-animation.scss */
.slide-top.ng-hide-remove {
  transform: translateY(60px);
  -ms-transform: translateY(60px);
  -webkit-transform: translateY(60px);
  transition-duration: 250ms;
  -webkit-transition-duration: 250ms;
  display: block !important;
  opacity: 0;
}
/* line 151, ../sass/ng-animation.scss */
.slide-top.ng-hide-remove.ng-hide-remove-active {
  transform: translateY(0);
  -ms-transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
}

/* ------------------------------------------- */
/* Slide Rigth 
/* ------------------------------------------- */
/* line 163, ../sass/ng-animation.scss */
.slide-right {
  -webkit-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -moz-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -ms-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -o-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
  /* easeOutQuad */
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -moz-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -ms-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -o-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  /* easeOutQuad */
}
/* line 165, ../sass/ng-animation.scss */
.slide-right.ng-enter {
  transform: translateX(60px);
  -ms-transform: translateX(60px);
  -webkit-transform: translateX(60px);
  transition-duration: 250ms;
  -webkit-transition-duration: 250ms;
  opacity: 0;
}
/* line 171, ../sass/ng-animation.scss */
.slide-right.ng-enter-active {
  transform: translateX(0);
  -ms-transform: translateX(0);
  -webkit-transform: translateX(0);
  opacity: 1;
}
/* line 176, ../sass/ng-animation.scss */
.slide-right.ng-leave {
  transform: translateX(0);
  -ms-transform: translateX(0);
  -webkit-transform: translateX(0);
  transition-duration: 250ms;
  -webkit-transition-duration: 250ms;
  opacity: 1;
}
/* line 182, ../sass/ng-animation.scss */
.slide-right.ng-leave-active {
  transform: translateX(60px);
  -ms-transform: translateX(60px);
  -webkit-transform: translateX(60px);
  opacity: 0;
}
/* line 188, ../sass/ng-animation.scss */
.slide-right.ng-hide-add {
  transform: translateX(0);
  -ms-transform: translateX(0);
  -webkit-transform: translateX(0);
  transition-duration: 250ms;
  -webkit-transition-duration: 250ms;
  opacity: 1;
}
/* line 193, ../sass/ng-animation.scss */
.slide-right.ng-hide-add.ng-hide-add-active {
  transform: translateX(60px);
  -ms-transform: translateX(60px);
  -webkit-transform: translateX(60px);
  opacity: 0;
}
/* line 199, ../sass/ng-animation.scss */
.slide-right.ng-hide-remove {
  transform: translateX(60px);
  -ms-transform: translateX(60px);
  -webkit-transform: translateX(60px);
  transition-duration: 250ms;
  -webkit-transition-duration: 250ms;
  display: block !important;
  opacity: 0;
}
/* line 205, ../sass/ng-animation.scss */
.slide-right.ng-hide-remove.ng-hide-remove-active {
  transform: translateX(0);
  -ms-transform: translateX(0);
  -webkit-transform: translateX(0);
  opacity: 1;
}

/* ------------------------------------------- */
/* Slide Left 
/* ------------------------------------------- */
/* line 217, ../sass/ng-animation.scss */
.slide-left {
  -webkit-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -moz-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -ms-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -o-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
  /* easeOutQuad */
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -moz-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -ms-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -o-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  /* easeOutQuad */
}
/* line 219, ../sass/ng-animation.scss */
.slide-left.ng-enter {
  transform: translateX(-60px);
  -ms-transform: translateX(-60px);
  -webkit-transform: translateX(-60px);
  transition-duration: 250ms;
  -webkit-transition-duration: 250ms;
  opacity: 0;
}
/* line 225, ../sass/ng-animation.scss */
.slide-left.ng-enter-active {
  transform: translateX(0);
  -ms-transform: translateX(0);
  -webkit-transform: translateX(0);
  opacity: 1;
}
/* line 230, ../sass/ng-animation.scss */
.slide-left.ng-leave {
  transform: translateX(0);
  -ms-transform: translateX(0);
  -webkit-transform: translateX(0);
  transition-duration: 250ms;
  -webkit-transition-duration: 250ms;
  opacity: 1;
}
/* line 236, ../sass/ng-animation.scss */
.slide-left.ng-leave-active {
  transform: translateX(-60px);
  -ms-transform: translateX(-60px);
  -webkit-transform: translateX(-60px);
  opacity: 0;
}
/* line 242, ../sass/ng-animation.scss */
.slide-left.ng-hide-add {
  transform: translateX(0);
  -ms-transform: translateX(0);
  -webkit-transform: translateX(0);
  transition-duration: 250ms;
  -webkit-transition-duration: 250ms;
  opacity: 1;
}
/* line 247, ../sass/ng-animation.scss */
.slide-left.ng-hide-add.ng-hide-add-active {
  transform: translateX(-60px);
  -ms-transform: translateX(-60px);
  -webkit-transform: translateX(-60px);
  opacity: 0;
}
/* line 253, ../sass/ng-animation.scss */
.slide-left.ng-hide-remove {
  transform: translateX(-60px);
  -ms-transform: translateX(-60px);
  -webkit-transform: translateX(-60px);
  transition-duration: 250ms;
  -webkit-transition-duration: 250ms;
  display: block !important;
  opacity: 0;
}
/* line 259, ../sass/ng-animation.scss */
.slide-left.ng-hide-remove.ng-hide-remove-active {
  transform: translateX(0);
  -ms-transform: translateX(0);
  -webkit-transform: translateX(0);
  opacity: 1;
}

/* ------------------------------------------- */
/* Slide Down
/* ------------------------------------------- */
/* line 270, ../sass/ng-animation.scss */
.slide-down {
  -webkit-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -moz-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -ms-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -o-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
  /* easeOutQuad */
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -moz-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -ms-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -o-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  /* easeOutQuad */
}
/* line 273, ../sass/ng-animation.scss */
.slide-down.ng-enter {
  transform: translateY(-60px);
  -ms-transform: translateY(-60px);
  -webkit-transform: translateY(-60px);
  transition-duration: 250ms;
  -webkit-transition-duration: 250ms;
  opacity: 0;
}
/* line 279, ../sass/ng-animation.scss */
.slide-down.ng-enter-active {
  transform: translateY(0);
  -ms-transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
}
/* line 284, ../sass/ng-animation.scss */
.slide-down.ng-leave {
  transform: translateY(0);
  -ms-transform: translateY(0);
  -webkit-transform: translateY(0);
  transition-duration: 250ms;
  -webkit-transition-duration: 250ms;
  opacity: 1;
}
/* line 290, ../sass/ng-animation.scss */
.slide-down.ng-leave-active {
  transform: translateY(-60px);
  -ms-transform: translateY(-60px);
  -webkit-transform: translateY(-60px);
  opacity: 0;
}
/* line 296, ../sass/ng-animation.scss */
.slide-down.ng-hide-add {
  transform: translateY(0);
  -ms-transform: translateY(0);
  -webkit-transform: translateY(0);
  transition-duration: 250ms;
  -webkit-transition-duration: 250ms;
  opacity: 1;
}
/* line 301, ../sass/ng-animation.scss */
.slide-down.ng-hide-add.ng-hide-add-active {
  transform: translateY(-60px);
  -ms-transform: translateY(-60px);
  -webkit-transform: translateY(-60px);
  opacity: 0;
}
/* line 307, ../sass/ng-animation.scss */
.slide-down.ng-hide-remove {
  transform: translateY(-60px);
  -ms-transform: translateY(-60px);
  -webkit-transform: translateY(-60px);
  transition-duration: 250ms;
  -webkit-transition-duration: 250ms;
  display: block !important;
  opacity: 0;
}
/* line 313, ../sass/ng-animation.scss */
.slide-down.ng-hide-remove.ng-hide-remove-active {
  transform: translateY(0);
  -ms-transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
}

/* ------------------------------------------- */
/* Bouncy Slide Top 
/* ------------------------------------------- */
/* line 325, ../sass/ng-animation.scss */
.bouncy-slide-top {
  -webkit-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1);
  /* older webkit */
  -webkit-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -ms-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
  /* easeOutBack */
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1);
  /* older webkit */
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
  -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
  -ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
  -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
  /* custom */
}
/* line 328, ../sass/ng-animation.scss */
.bouncy-slide-top.ng-enter {
  transform: translateY(240px);
  -ms-transform: translateY(240px);
  -webkit-transform: translateY(240px);
  transition-duration: 250ms;
  -webkit-transition-duration: 250ms;
  opacity: 0;
}
/* line 334, ../sass/ng-animation.scss */
.bouncy-slide-top.ng-enter-active {
  transform: translateY(0);
  -ms-transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
}
/* line 339, ../sass/ng-animation.scss */
.bouncy-slide-top.ng-leave {
  transform: translateY(0);
  -ms-transform: translateY(0);
  -webkit-transform: translateY(0);
  transition-duration: 250ms;
  -webkit-transition-duration: 250ms;
  opacity: 1;
}
/* line 345, ../sass/ng-animation.scss */
.bouncy-slide-top.ng-leave-active {
  transform: translateY(240px);
  -ms-transform: translateY(240px);
  -webkit-transform: translateY(240px);
  opacity: 0;
}
/* line 351, ../sass/ng-animation.scss */
.bouncy-slide-top.ng-hide-add {
  transform: translateY(0);
  -ms-transform: translateY(0);
  -webkit-transform: translateY(0);
  transition-duration: 250ms;
  -webkit-transition-duration: 250ms;
  opacity: 1;
}
/* line 356, ../sass/ng-animation.scss */
.bouncy-slide-top.ng-hide-add.ng-hide-add-active {
  transform: translateY(240px);
  -ms-transform: translateY(240px);
  -webkit-transform: translateY(240px);
  opacity: 0;
}
/* line 362, ../sass/ng-animation.scss */
.bouncy-slide-top.ng-hide-remove {
  transform: translateY(240px);
  -ms-transform: translateY(240px);
  -webkit-transform: translateY(240px);
  transition-duration: 250ms;
  -webkit-transition-duration: 250ms;
  display: block !important;
  opacity: 0;
}
/* line 368, ../sass/ng-animation.scss */
.bouncy-slide-top.ng-hide-remove.ng-hide-remove-active {
  transform: translateY(0);
  -ms-transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
}

/* ------------------------------------------- */
/* Bouncy Slide Rigth 
/* ------------------------------------------- */
/* line 379, ../sass/ng-animation.scss */
.bouncy-slide-right {
  -webkit-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1);
  /* older webkit */
  -webkit-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -ms-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
  /* easeOutBack */
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1);
  /* older webkit */
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
  -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
  -ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
  -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
  /* custom */
}
/* line 382, ../sass/ng-animation.scss */
.bouncy-slide-right.ng-enter {
  transform: translateX(240px);
  -ms-transform: translateX(240px);
  -webkit-transform: translateX(240px);
  transition-duration: 250ms;
  -webkit-transition-duration: 250ms;
  opacity: 0;
}
/* line 388, ../sass/ng-animation.scss */
.bouncy-slide-right.ng-enter-active {
  transform: translateX(0);
  -ms-transform: translateX(0);
  -webkit-transform: translateX(0);
  opacity: 1;
}
/* line 393, ../sass/ng-animation.scss */
.bouncy-slide-right.ng-leave {
  transform: translateX(0);
  -ms-transform: translateX(0);
  -webkit-transform: translateX(0);
  transition-duration: 250ms;
  -webkit-transition-duration: 250ms;
  opacity: 1;
}
/* line 399, ../sass/ng-animation.scss */
.bouncy-slide-right.ng-leave-active {
  transform: translateX(240px);
  -ms-transform: translateX(240px);
  -webkit-transform: translateX(240px);
  opacity: 0;
}
/* line 405, ../sass/ng-animation.scss */
.bouncy-slide-right.ng-hide-add {
  transform: translateX(0);
  -ms-transform: translateX(0);
  -webkit-transform: translateX(0);
  transition-duration: 250ms;
  -webkit-transition-duration: 250ms;
  opacity: 1;
}
/* line 410, ../sass/ng-animation.scss */
.bouncy-slide-right.ng-hide-add.ng-hide-add-active {
  transform: translateX(240px);
  -ms-transform: translateX(240px);
  -webkit-transform: translateX(240px);
  opacity: 0;
}
/* line 416, ../sass/ng-animation.scss */
.bouncy-slide-right.ng-hide-remove {
  transform: translateX(240px);
  -ms-transform: translateX(240px);
  -webkit-transform: translateX(240px);
  transition-duration: 250ms;
  -webkit-transition-duration: 250ms;
  display: block !important;
  opacity: 0;
}
/* line 422, ../sass/ng-animation.scss */
.bouncy-slide-right.ng-hide-remove.ng-hide-remove-active {
  transform: translateX(0);
  -ms-transform: translateX(0);
  -webkit-transform: translateX(0);
  opacity: 1;
}

/* ------------------------------------------- */
/* Bouncy Slide Left 
/* ------------------------------------------- */
/* line 434, ../sass/ng-animation.scss */
.bouncy-slide-left {
  -webkit-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1);
  /* older webkit */
  -webkit-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -ms-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
  /* easeOutBack */
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1);
  /* older webkit */
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
  -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
  -ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
  -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
  /* custom */
}
/* line 437, ../sass/ng-animation.scss */
.bouncy-slide-left.ng-enter {
  transform: translateX(-240px);
  -ms-transform: translateX(-240px);
  -webkit-transform: translateX(-240px);
  transition-duration: 250ms;
  -webkit-transition-duration: 250ms;
  opacity: 0;
}
/* line 443, ../sass/ng-animation.scss */
.bouncy-slide-left.ng-enter-active {
  transform: translateX(0);
  -ms-transform: translateX(0);
  -webkit-transform: translateX(0);
  opacity: 1;
}
/* line 448, ../sass/ng-animation.scss */
.bouncy-slide-left.ng-leave {
  transform: translateX(0);
  -ms-transform: translateX(0);
  -webkit-transform: translateX(0);
  transition-duration: 250ms;
  -webkit-transition-duration: 250ms;
  opacity: 1;
}
/* line 454, ../sass/ng-animation.scss */
.bouncy-slide-left.ng-leave-active {
  transform: translateX(-240px);
  -ms-transform: translateX(-240px);
  -webkit-transform: translateX(-240px);
  opacity: 0;
}
/* line 460, ../sass/ng-animation.scss */
.bouncy-slide-left.ng-hide-add {
  transform: translateX(0);
  -ms-transform: translateX(0);
  -webkit-transform: translateX(0);
  transition-duration: 250ms;
  -webkit-transition-duration: 250ms;
  opacity: 1;
}
/* line 465, ../sass/ng-animation.scss */
.bouncy-slide-left.ng-hide-add.ng-hide-add-active {
  transform: translateX(-240px);
  -ms-transform: translateX(-240px);
  -webkit-transform: translateX(-240px);
  opacity: 0;
}
/* line 471, ../sass/ng-animation.scss */
.bouncy-slide-left.ng-hide-remove {
  transform: translateX(-240px);
  -ms-transform: translateX(-240px);
  -webkit-transform: translateX(-240px);
  transition-duration: 250ms;
  -webkit-transition-duration: 250ms;
  display: block !important;
  opacity: 0;
}
/* line 477, ../sass/ng-animation.scss */
.bouncy-slide-left.ng-hide-remove.ng-hide-remove-active {
  transform: translateX(0);
  -ms-transform: translateX(0);
  -webkit-transform: translateX(0);
  opacity: 1;
}

/* ------------------------------------------- */
/* Bouncy Slide Down
/* ------------------------------------------- */
/* line 488, ../sass/ng-animation.scss */
.bouncy-slide-down {
  -webkit-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1);
  /* older webkit */
  -webkit-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -ms-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
  /* easeOutBack */
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1);
  /* older webkit */
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
  -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
  -ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
  -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
  /* custom */
}
/* line 491, ../sass/ng-animation.scss */
.bouncy-slide-down.ng-enter {
  transform: translateY(-240px);
  -ms-transform: translateY(-240px);
  -webkit-transform: translateY(-240px);
  transition-duration: 250ms;
  -webkit-transition-duration: 250ms;
  opacity: 0;
}
/* line 497, ../sass/ng-animation.scss */
.bouncy-slide-down.ng-enter-active {
  transform: translateY(0);
  -ms-transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
}
/* line 502, ../sass/ng-animation.scss */
.bouncy-slide-down.ng-leave {
  transform: translateY(0);
  -ms-transform: translateY(0);
  -webkit-transform: translateY(0);
  transition-duration: 250ms;
  -webkit-transition-duration: 250ms;
  opacity: 1;
}
/* line 508, ../sass/ng-animation.scss */
.bouncy-slide-down.ng-leave-active {
  transform: translateY(-240px);
  -ms-transform: translateY(-240px);
  -webkit-transform: translateY(-240px);
  opacity: 0;
}
/* line 514, ../sass/ng-animation.scss */
.bouncy-slide-down.ng-hide-add {
  transform: translateY(0);
  -ms-transform: translateY(0);
  -webkit-transform: translateY(0);
  transition-duration: 250ms;
  -webkit-transition-duration: 250ms;
  opacity: 1;
}
/* line 519, ../sass/ng-animation.scss */
.bouncy-slide-down.ng-hide-add.ng-hide-add-active {
  transform: translateY(-240px);
  -ms-transform: translateY(-240px);
  -webkit-transform: translateY(-240px);
  opacity: 0;
}
/* line 525, ../sass/ng-animation.scss */
.bouncy-slide-down.ng-hide-remove {
  transform: translateY(-240px);
  -ms-transform: translateY(-240px);
  -webkit-transform: translateY(-240px);
  transition-duration: 250ms;
  -webkit-transition-duration: 250ms;
  display: block !important;
  opacity: 0;
}
/* line 531, ../sass/ng-animation.scss */
.bouncy-slide-down.ng-hide-remove.ng-hide-remove-active {
  transform: translateY(0);
  -ms-transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
}

/* ------------------------------------------- */
/* Scale Fade 
/* ------------------------------------------- */
/* line 544, ../sass/ng-animation.scss */
.scale-fade {
  -webkit-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -moz-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -ms-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -o-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
  /* easeOutQuad */
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -moz-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -ms-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -o-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  /* easeOutQuad */
}
/* line 547, ../sass/ng-animation.scss */
.scale-fade.ng-enter {
  transform: scale(0.7);
  -ms-transform: scale(0.7);
  -webkit-transform: scale(0.7);
  transition-duration: 250ms;
  -webkit-transition-duration: 250ms;
  opacity: 0;
}
/* line 553, ../sass/ng-animation.scss */
.scale-fade.ng-enter-active {
  transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  opacity: 1;
}
/* line 558, ../sass/ng-animation.scss */
.scale-fade.ng-leave {
  transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  transition-duration: 250ms;
  -webkit-transition-duration: 250ms;
  opacity: 1;
}
/* line 564, ../sass/ng-animation.scss */
.scale-fade.ng-leave-active {
  transform: scale(0.7);
  -ms-transform: scale(0.7);
  -webkit-transform: scale(0.7);
  opacity: 0;
}
/* line 570, ../sass/ng-animation.scss */
.scale-fade.ng-hide-add {
  transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  transition-duration: 250ms;
  -webkit-transition-duration: 250ms;
  opacity: 1;
}
/* line 575, ../sass/ng-animation.scss */
.scale-fade.ng-hide-add.ng-hide-add-active {
  transform: scale(0.7);
  -ms-transform: scale(0.7);
  -webkit-transform: scale(0.7);
  opacity: 0;
}
/* line 581, ../sass/ng-animation.scss */
.scale-fade.ng-hide-remove {
  transform: scale(0.7);
  -ms-transform: scale(0.7);
  -webkit-transform: scale(0.7);
  transition-duration: 250ms;
  -webkit-transition-duration: 250ms;
  display: block !important;
  opacity: 0;
}
/* line 587, ../sass/ng-animation.scss */
.scale-fade.ng-hide-remove.ng-hide-remove-active {
  transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  opacity: 1;
}

/* ------------------------------------------- */
/* Spin Fade 
/* ------------------------------------------- */
/* line 598, ../sass/ng-animation.scss */
.spin-toggle {
  -webkit-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -moz-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -ms-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -o-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
  /* easeOutQuad */
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -moz-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -ms-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -o-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  /* easeOutQuad */
}
/* line 601, ../sass/ng-animation.scss */
.spin-toggle.ng-enter {
  transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  -webkit-transform: rotate(225deg);
  transition-duration: 250ms;
  -webkit-transition-duration: 250ms;
  opacity: 0;
}
/* line 607, ../sass/ng-animation.scss */
.spin-toggle.ng-enter-active {
  transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  opacity: 1;
}
/* line 612, ../sass/ng-animation.scss */
.spin-toggle.ng-leave {
  transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transition-duration: 250ms;
  -webkit-transition-duration: 250ms;
  opacity: 1;
}
/* line 618, ../sass/ng-animation.scss */
.spin-toggle.ng-leave-active {
  transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  opacity: 0;
}
/* line 624, ../sass/ng-animation.scss */
.spin-toggle.ng-hide-add {
  transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transition-duration: 250ms;
  -webkit-transition-duration: 250ms;
  opacity: 1;
}
/* line 629, ../sass/ng-animation.scss */
.spin-toggle.ng-hide-add.ng-hide-add-active {
  transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  opacity: 0;
}
/* line 635, ../sass/ng-animation.scss */
.spin-toggle.ng-hide-remove {
  transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  -webkit-transform: rotate(225deg);
  transition-duration: 250ms;
  -webkit-transition-duration: 250ms;
  display: block !important;
  opacity: 0;
}
/* line 641, ../sass/ng-animation.scss */
.spin-toggle.ng-hide-remove.ng-hide-remove-active {
  transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  opacity: 1;
}

/* ------------------------------------------- */
/* Scale in Fade 
/* ------------------------------------------- */
/* line 653, ../sass/ng-animation.scss */
.scale-fade-in {
  -webkit-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -moz-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -ms-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -o-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
  /* easeOutQuad */
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -moz-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -ms-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -o-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  /* easeOutQuad */
}
/* line 656, ../sass/ng-animation.scss */
.scale-fade-in.ng-enter {
  transform: scale(3);
  -ms-transform: scale(3);
  -webkit-transform: scale(3);
  transition-duration: 250ms;
  -webkit-transition-duration: 250ms;
  opacity: 0;
}
/* line 662, ../sass/ng-animation.scss */
.scale-fade-in.ng-enter-active {
  transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  opacity: 1;
}
/* line 667, ../sass/ng-animation.scss */
.scale-fade-in.ng-leave {
  transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  transition-duration: 250ms;
  -webkit-transition-duration: 250ms;
  opacity: 1;
}
/* line 673, ../sass/ng-animation.scss */
.scale-fade-in.ng-leave-active {
  transform: scale(3);
  -ms-transform: scale(3);
  -webkit-transform: scale(3);
  opacity: 0;
}
/* line 679, ../sass/ng-animation.scss */
.scale-fade-in.ng-hide-add {
  transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  transition-duration: 250ms;
  -webkit-transition-duration: 250ms;
  opacity: 1;
}
/* line 684, ../sass/ng-animation.scss */
.scale-fade-in.ng-hide-add.ng-hide-add-active {
  transform: scale(3);
  -ms-transform: scale(3);
  -webkit-transform: scale(3);
  opacity: 0;
}
/* line 690, ../sass/ng-animation.scss */
.scale-fade-in.ng-hide-remove {
  transform: scale(3);
  -ms-transform: scale(3);
  -webkit-transform: scale(3);
  transition-duration: 250ms;
  -webkit-transition-duration: 250ms;
  display: block !important;
  opacity: 0;
}
/* line 696, ../sass/ng-animation.scss */
.scale-fade-in.ng-hide-remove.ng-hide-remove-active {
  transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  opacity: 1;
}

/* ------------------------------------------- */
/* Bouncy Scale in Fade 
/* ------------------------------------------- */
/* line 708, ../sass/ng-animation.scss */
.bouncy-scale-in {
  -webkit-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1);
  /* older webkit */
  -webkit-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -ms-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
  /* easeOutBack */
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1);
  /* older webkit */
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
  -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
  -ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
  -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
  /* custom */
}
/* line 711, ../sass/ng-animation.scss */
.bouncy-scale-in.ng-enter {
  transform: scale(3);
  -ms-transform: scale(3);
  -webkit-transform: scale(3);
  transition-duration: 450ms;
  -webkit-transition-duration: 450ms;
  opacity: 0;
}
/* line 717, ../sass/ng-animation.scss */
.bouncy-scale-in.ng-enter-active {
  transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  opacity: 1;
}
/* line 722, ../sass/ng-animation.scss */
.bouncy-scale-in.ng-leave {
  transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  transition-duration: 450ms;
  -webkit-transition-duration: 450ms;
  opacity: 1;
}
/* line 728, ../sass/ng-animation.scss */
.bouncy-scale-in.ng-leave-active {
  transform: scale(3);
  -ms-transform: scale(3);
  -webkit-transform: scale(3);
  opacity: 0;
}
/* line 734, ../sass/ng-animation.scss */
.bouncy-scale-in.ng-hide-add {
  transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  transition-duration: 450ms;
  -webkit-transition-duration: 450ms;
  opacity: 1;
}
/* line 739, ../sass/ng-animation.scss */
.bouncy-scale-in.ng-hide-add.ng-hide-add-active {
  transform: scale(3);
  -ms-transform: scale(3);
  -webkit-transform: scale(3);
  opacity: 0;
}
/* line 745, ../sass/ng-animation.scss */
.bouncy-scale-in.ng-hide-remove {
  transform: scale(3);
  -ms-transform: scale(3);
  -webkit-transform: scale(3);
  transition-duration: 450ms;
  -webkit-transition-duration: 450ms;
  display: block !important;
  opacity: 0;
}
/* line 751, ../sass/ng-animation.scss */
.bouncy-scale-in.ng-hide-remove.ng-hide-remove-active {
  transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  opacity: 1;
}

/* ------------------------------------------- */
/* Flip In 
/* ------------------------------------------- */
/* line 763, ../sass/ng-animation.scss */
.flip-in {
  -webkit-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -moz-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -ms-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -o-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
  /* easeOutQuad */
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -moz-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -ms-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -o-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  /* easeOutQuad */
}
/* line 766, ../sass/ng-animation.scss */
.flip-in.ng-enter {
  transform: perspective(300px) rotateX(90deg);
  -ms-transform: perspective(300px) rotateX(90deg);
  -webkit-transform: perspective(300px) rotateX(90deg);
  transition-duration: 550ms;
  -webkit-transition-duration: 550ms;
  opacity: 0.7;
}
/* line 772, ../sass/ng-animation.scss */
.flip-in.ng-enter-active {
  transform: perspective(300px) rotateX(0deg);
  -ms-transform: perspective(300px) rotateX(0deg);
  -webkit-transform: perspective(300px) rotateX(0deg);
  opacity: 1;
}
/* line 777, ../sass/ng-animation.scss */
.flip-in.ng-leave {
  transform: perspective(300px) rotateX(0deg);
  -ms-transform: perspective(300px) rotateX(0deg);
  -webkit-transform: perspective(300px) rotateX(0deg);
  transition-duration: 550ms;
  -webkit-transition-duration: 550ms;
  opacity: 1;
}
/* line 783, ../sass/ng-animation.scss */
.flip-in.ng-leave-active {
  transform: perspective(300px) rotateX(135deg);
  -ms-transform: perspective(300px) rotateX(135deg);
  -webkit-transform: perspective(300px) rotateX(135deg);
  opacity: 0.7;
}
/* line 789, ../sass/ng-animation.scss */
.flip-in.ng-hide-add {
  transform: perspective(300px) rotateX(0deg);
  -ms-transform: perspective(300px) rotateX(0deg);
  -webkit-transform: perspective(300px) rotateX(0deg);
  transition-duration: 550ms;
  -webkit-transition-duration: 550ms;
  opacity: 1;
}
/* line 794, ../sass/ng-animation.scss */
.flip-in.ng-hide-add.ng-hide-add-active {
  transform: perspective(300px) rotateX(135deg);
  -ms-transform: perspective(300px) rotateX(135deg);
  -webkit-transform: perspective(300px) rotateX(135deg);
  opacity: 0;
}
/* line 800, ../sass/ng-animation.scss */
.flip-in.ng-hide-remove {
  transform: perspective(300px) rotateX(90deg);
  -ms-transform: perspective(300px) rotateX(90deg);
  -webkit-transform: perspective(300px) rotateX(90deg);
  transition-duration: 550ms;
  -webkit-transition-duration: 550ms;
  display: block !important;
  opacity: 0;
}
/* line 806, ../sass/ng-animation.scss */
.flip-in.ng-hide-remove.ng-hide-remove-active {
  transform: perspective(300px) rotateX(0deg);
  -ms-transform: perspective(300px) rotateX(0deg);
  -webkit-transform: perspective(300px) rotateX(0deg);
  opacity: 1;
}

/* ------------------------------------------- */
/* Rotate In 
/* ------------------------------------------- */
/* line 817, ../sass/ng-animation.scss */
.rotate-in {
  -webkit-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1);
  /* older webkit */
  -webkit-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -ms-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
  /* easeOutBack */
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1);
  /* older webkit */
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
  -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
  -ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
  -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
  /* custom */
}
/* line 820, ../sass/ng-animation.scss */
.rotate-in.ng-enter {
  transform: perspective(300px) rotateY(40deg);
  -ms-transform: perspective(300px) rotateY(40deg);
  -webkit-transform: perspective(300px) rotateY(40deg);
  transition-duration: 550ms;
  -webkit-transition-duration: 550ms;
  opacity: 0.7;
}
/* line 826, ../sass/ng-animation.scss */
.rotate-in.ng-enter-active {
  transform: perspective(300px) rotateY(0deg);
  -ms-transform: perspective(300px) rotateY(0deg);
  -webkit-transform: perspective(300px) rotateY(0deg);
  opacity: 1;
}
/* line 831, ../sass/ng-animation.scss */
.rotate-in.ng-leave {
  transform: perspective(300px) rotateY(0deg);
  -ms-transform: perspective(300px) rotateY(0deg);
  -webkit-transform: perspective(300px) rotateY(0deg);
  transition-duration: 550ms;
  -webkit-transition-duration: 550ms;
  opacity: 1;
}
/* line 837, ../sass/ng-animation.scss */
.rotate-in.ng-leave-active {
  transform: perspective(300px) rotateY(-40deg);
  -ms-transform: perspective(300px) rotateY(-40deg);
  -webkit-transform: perspective(300px) rotateY(-40deg);
  opacity: 0.7;
}
/* line 843, ../sass/ng-animation.scss */
.rotate-in.ng-hide-add {
  transform: perspective(300px) rotateY(0deg);
  -ms-transform: perspective(300px) rotateY(0deg);
  -webkit-transform: perspective(300px) rotateY(0deg);
  transition-duration: 550ms;
  -webkit-transition-duration: 550ms;
  opacity: 1;
}
/* line 848, ../sass/ng-animation.scss */
.rotate-in.ng-hide-add.ng-hide-add-active {
  transform: perspective(300px) rotateY(-40deg);
  -ms-transform: perspective(300px) rotateY(-40deg);
  -webkit-transform: perspective(300px) rotateY(-40deg);
  opacity: 0;
}
/* line 854, ../sass/ng-animation.scss */
.rotate-in.ng-hide-remove {
  transform: perspective(300px) rotateY(40deg);
  -ms-transform: perspective(300px) rotateY(40deg);
  -webkit-transform: perspective(300px) rotateY(40deg);
  transition-duration: 550ms;
  -webkit-transition-duration: 550ms;
  display: block !important;
  opacity: 0;
}
/* line 860, ../sass/ng-animation.scss */
.rotate-in.ng-hide-remove.ng-hide-remove-active {
  transform: perspective(300px) rotateY(0deg);
  -ms-transform: perspective(300px) rotateY(0deg);
  -webkit-transform: perspective(300px) rotateY(0deg);
  opacity: 1;
}

/* ------------------------------------------- */
/* Glyph spin 
/* ------------------------------------------- */

.gly-spin {
  -webkit-animation: spin 1s infinite linear;
  -moz-animation: spin 1s infinite linear;
  -o-animation: spin 1s infinite linear;
  animation: spin 1s infinite linear;
}
@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(359deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
  }
}
@-o-keyframes spin {
  0% {
    -o-transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
.gly-rotate-90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.gly-rotate-180 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.gly-rotate-270 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}
.gly-flip-horizontal {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
  -webkit-transform: scale(-1, 1);
  -moz-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
.gly-flip-vertical {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1);
  -webkit-transform: scale(1, -1);
  -moz-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  -o-transform: scale(1, -1);
  transform: scale(1, -1);
}